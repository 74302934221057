<template>
    <div class="container communityGuidelinesMainDiv">
        <div class="communityGuidelinesHeading">
            <h2>RxIx Community Guidelines for</h2>
        </div>
        <div>
            <p class="communityGuidelinesSubHeading py-2">RxIx Patient Support Groups, RxIx Online Communities managed by
                HCPs
                and RxIx Communities for HCPs
            </p>
            <p class="py-2">
                RxIx Patient Support Groups, RxIx Online Communities managed by HCPs and RxIx Communities for HCPs (together
                called the “RxIx communities”) supports sharing of knowledge, experience, and wisdom between patients,
                between HCPs, and between HCPs and patients. The word “patients” includes their friends, families, loved
                ones and carers.
            </p>
            <p class="py-2">
                Membership to these groups and communities is restricted to Registered End Users and Registered HCPs, as the
                case maybe. The groups and the communities may be moderated by volunteers of End Users, HCPs, or RxIx
                representatives.
            </p>
            <p class="py-2">
                Members of the RxIx communities should read the Guidelines below that cover the whole of the RxIx site. By
                using the Site (as defined in the End User Terms and Conditions and the HCP Terms and Conditions), the End
                User Services, the HCP services, or agreeing to the respective terms and conditions, you are accepting these
                community guidelines.
            </p>
            <p class="py-1">
                1. The aim of RxIx communities is to provide a safe and friendly environment where users can share
                information and offer mutual support in the End User’s healthcare journey.
            </p>
            <p class="py-1">
                2. The RxIx patient support groups and the online communities managed by HCPs are open sites. This means
                that any ‘open’ Posts are ‘searchable’ via any Internet search engine and can also be shared on select
                social media platforms.
            </p>
            <p class="py-1">
                3. For your own safety, you should not disclose personal information such as your e-mail address, home
                address or telephone number (or any other information which could identify you indirectly) on posts and
                questions published to the members of the RxIx communities.
            </p>
            <p class="py-1">
                4. These posting guidelines are additional to those found in the End User Terms and Conditions and the HCP
                Terms and Conditions. We encourage you to review the End User Terms and Conditions and the HCP Terms and
                Conditions periodically to check you understand the latest versions. Your continued use of the Site is
                deemed to be your acceptance of any changes to this document and the above references. If you are not happy
                with any amendments, you should stop using the Site.
            </p>
            <p class="py-1">
                5. We hope that users will respect the right of other members to present their individual health
                experiences, regardless of whether they are in agreement or not, and that all disagreements will be
                expressed considerately.
            </p>
            <p class="py-1">
                6. The Company may have a moderator to manage the interactions in the patient support group. If such a
                moderator is present, he is entitled to take a decision on any of the disputes arising in the online
                interactions of the support groups. End Users will abide by such decision. However, if any End User is not
                satisfied with the moderator’s decision, he may bring it to the attention of the Company by submitting a
                message on the Company’s Contact Us page.
            </p>
            <p class="communityGuidelinesSubHeading py-2">
                RxIx Community Posting Guidelines
            </p>
            <p class="py-1">
                1. Your use of RxIx communities is entirely at your own risk and people posting and/or answering on the site
                must not be assumed to be healthcare professionals of any type.
            </p>
            <p class="py-1">
                2. Please be aware that because of the nature of the Site, users need to be mindful and respectful of any
                content that may cause upset or offend fellow users. Such content may be removed at the discretion of the
                admin team.
            </p>
            <p class="py-1">
                3. All posts and messages placed on RxIx communities are the opinion of the author and are not the views and
                opinions of the Company. Posts, Answers and Comments posted on the website should be intended to support
                members and not replace medical advice.
            </p>
            <p class="py-1">
                4. Posts must not be over lengthy and a word limit of 2,500 words (approx. 1 A4 side) is suggested.
                Over-long posts may be edited or deleted by the admin team or the poster may be asked to edit, at the
                discretion of the admin team.
            </p>
            <p class="py-1">
                5. You must not post any information or use the Site’s features in a way that may be invasive of another’s
                privacy or violate the rights of any other user, or of any other third party in any way.
            </p>
            <p class="py-1">
                6. All members must conduct themselves at all times with respect, tact and honesty toward all users,. You
                are responsible for the content of the messages you post and any consequences that flow from such
                posting(s). We want all members to feel safe and comfortable posting here. In order to ensure that happens,
                any post or comment that causes distress, alarm, offence or harassment to any other person, whether intended
                or not, may be removed at the discretion of the admin team.
            </p>
            <p class="py-1">
                7. Please do not post any information that is knowingly untrue, a misrepresentation, unlawful, harmful,
                threatening, abusive, harassing, vulgar, profane, obscene, that may cause personal distress, offensive,
                defamatory, false, libellous, hateful, disrespectful, racially, sexually or otherwise discriminatory,
                confidential and/or commercially sensitive or post any information that is otherwise (at the absolute
                discretion of the Company) objectionable. Such content will be removed at the discretion of the admin team.
            </p>
            <p class="py-1">
                8. Do not name, shame or be critical of identifiable individuals, including other forum members and members
                of the Admin team, either on the Site or in any other social media platform. Names/comments may be edited
                and/or deleted at the discretion of the RxIx community moderators.
            </p>
            <p class="py-1">
                9. Messages that are critical of individuals or health professionals, specific treatments, services or
                treatment units will be removed. This is to ensure that others are not discouraged from getting the help
                they need.
            </p>
            <p class="py-1">
                10. Whilst religious well wishes are permitted, please do not proselytise or use religious text or
                quotations in your posts. Likewise, do not post content of a political nature. Doing so is always divisive
                and contentious and everyone on the site must feel welcome regardless of their religious or political
                beliefs. Do not post links to religious or political sites. Such content may be removed at the discretion of
                the admin team.
            </p>
            <p class="py-1">
                11. Please do not promote the use of alternative treatments, as opposed to complementary therapies.
                Alternative treatments may deter members from seeking appropriate professional medical assistance.
            </p>
            <p class="py-1">
                12. It is understood that whilst diagnoses, symptoms, conditions and treatments may be subject to
                speculation and experiential sharing between members, posts must never intend or be construed as giving
                definite medical or treatment advice. No advice given on the site can be viewed as an alternative to that
                provided by a member’s professional medical team.
            </p>
            <p class="py-1">
                13. Posts must not impersonate any person or entity, or misrepresent your association with a person or
                entity. This includes overuse of the word ‘we’. If it is your opinion then state it as such and not as a
                collective ‘we’.
            </p>
            <p class="py-1">
                14. As a user you must be aware that there will be constant monitoring of the RxIx communities. Any messages
                that users deem to be unacceptable will be removed at the sole discretion of the RxIx community
                administrators and/or moderators.
            </p>
            <p class="py-1">
                15. The RxIx community administrators reserve the right to delete any Post or Comment they judge
                inappropriate, without notifying the author. The member may be informed of the reason the content was
                deleted and invited to rewrite it.
            </p>
            <p class="py-1">
                16. The RxIx community Admin team reserves the right to restrict any member to ‘read only’. An explanation
                may be provided via message. Anyone who persistently posts unacceptable messages that contravene these
                guidelines may be permanently excluded from using this service at the sole discretion of the RxIx community
                administrators.
            </p>
            <p class="py-1">
                17. You may not be allowed to post external links. If you are allowed and if you post a link, please at
                least explain briefly what people can expect to find if they follow the link. Links without such an
                introduction/explanation may be deleted.
            </p>
            <p class="py-1">
                18. The Company and the RxIx community administrators and moderators cannot be held responsible for the
                content of external websites and such links may be deleted, if felt to be inappropriate.
            </p>
            <p class="py-1">
                19. Do not solicit members to other websites or forums for your own purposes, including marketing of books
                or other items, either via posts or the messages system.
            </p>
            <p class="py-1">
                20. Please show a clear distinction between personal experience or opinion and evidence-based information.
                Theories or supposition that are not supported by evidence – i.e. a link to that evidence, must be made
                clear that it is personal conjecture and not fact.
            </p>
            <p class="py-1">
                21. Do not breach copyright rules by placing documents onto the RxIx communities unless you have permission
                from the author of the document. You may, however, be allowed to post excerpts with links to the full text
                of the document. Do not violate, plagiarise, or infringe on the rights of third parties (including
                copyright, trademarks, trade secrets, privacy, personal, publicity or proprietary rights).
            </p>
            <p class="py-1">
                22. The Company reserve the right to remove references to films, television programmes, books, newspapers,
                magazines etc., which we do not believe are appropriate for the RxIx community members.
            </p>
            <p class="py-1">
                23. Spamming or advertising IS NOT ALLOWED. You must not promote or endorse any business or commercial
                goods. Any Post or Comment considered to be advertising may be edited and/or deleted at the discretion of
                the RxIx community administrators.
            </p>
            <p class="py-1">
                24. The Company will not be liable for any errors or omissions in any postings or for any loss or damage
                incurred as a result of the use of any information contained in the RxIx communities.
            </p>
            <p class="py-1">
                25. The Company makes no guarantee or gives warranty of any kind whatsoever (whether expressed or implied)
                in relation to your use of the RxIx communities or any use by third parties.
            </p>
            <p class="py-1">
                26. The Company cannot be held responsible for the content of ‘Private Messages’ or the outcome of any
                action taken as a result of any such message. The messaging system is private and cannot be accessed by the
                RxIx community administrators. If members choose to make contact via email, telephone or in person, the
                Company cannot be held responsible for the outcome.
            </p>
            <p class="py-1">
                27. The Company shall not be liable for any loss or damage (whether direct, indirect, consequential,
                financial or otherwise) arising from the use of (or in connection with) the RxIx communities.
            </p>
            <p class="py-1">
                28. The Company cannot be held responsible for any discussions regarding self-treatment or the outcome of
                any such discussions.
            </p>
            <p class="py-1">
                29. Members who wish to express their dissatisfaction with any administrative aspects of the Site should
                raise their concerns with a member or members of the admin team using the Contact Us page and not via a post
                or comment on the site. Any such content on the site may be removed at the discretion of the admin team.
            </p>
            <p class="py-1">
                30. Please report any abusive or inappropriate Posts, Comments or Private Messages. Posts and comments
                (replies) can be reported to the community admins by checking the box, Report to Admin.
            </p>
            <p class="py-1">
                31. Any Post or Comment that appears to be asking for money, unless approved by the Company, will be edited
                and/or deleted at the discretion of the RxIx community administrators or moderators. To post a request for
                raising money, please contact the admin using the Contact Us page.
            </p>
            <p class="py-1">
                32. Media requests, survey requests, requests seeking academic or market research information, must be made
                to the Company. Such posts are not permitted directly to RxIx communities without the explicit approval of
                the Company.
            </p>
            <p class="py-2">
                If an account is suspected of violating any of these guidelines as stated above, particularly in the
                instance of bullying or spamming, that member’s account may be restricted or banned.
            </p>
            <p class="py-2">For any clarification and suggestion on these guidelines, you may contact Mr. V Rameshwar at
                community@rxix.in. We will respond to your request within a reasonable time.
            </p>
            <p class="py-2">
                Any changes to these guidelines will be posted on the Site and will become effective as of the date of
                posting. Please review the Community Guidelines from time to time to make sure you are aware of any changes.
                If you do not agree with any such revised terms, please refrain from using our Services and contact us to
                terminate any account you may have created.
            </p>
        </div>
    </div>
</template>
<style>
.communityGuidelinesMainDiv {
    text-align: justify;
}

.communityGuidelinesHeading {
    font-size: 22px;
    color: black;
    font-weight: 550;
    margin-top: 5rem;
}

.communityGuidelinesSubHeading {
    font-size: 19px;
    color: black;
    font-weight: 550;
}</style>